/* Exo 2 */

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 100;
  src: url("./Exo_2/Exo2-Thin.ttf"); /* IE9 Compat Modes */
  src: local("Exo 2"), url("./Exo_2/Exo2-Thin.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 200;
  src: url("./Exo_2/Exo2-ExtraLight.ttf"); /* IE9 Compat Modes */
  src: local("Exo 2"), url("./Exo_2/Exo2-ExtraLight.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 300;
  src: url("./Exo_2/Exo2-Light.ttf"); /* IE9 Compat Modes */
  src: local("Exo 2"), url("./Exo_2/Exo2-Light.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  src: url("./Exo_2/Exo2-Regular.ttf"); /* IE9 Compat Modes */
  src: local("Exo 2"), url("./Exo_2/Exo2-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 500;
  src: url("./Exo_2/Exo2-Medium.ttf"); /* IE9 Compat Modes */
  src: local("Exo 2"), url("./Exo_2/Exo2-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 600;
  src: url("./Exo_2/Exo2-SemiBold.ttf"); /* IE9 Compat Modes */
  src: local("Exo 2"), url("./Exo_2/Exo2-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 700;
  src: url("./Exo_2/Exo2-Bold.ttf"); /* IE9 Compat Modes */
  src: local("Exo 2"), url("./Exo_2/Exo2-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 800;
  src: url("./Exo_2/Exo2-ExtraBold.ttf"); /* IE9 Compat Modes */
  src: local("Exo 2"), url("./Exo_2/Exo2-ExtraBold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 900;
  src: url("./Exo_2/Exo2-Black.ttf"); /* IE9 Compat Modes */
  src: local("Exo 2"), url("./Exo_2/Exo2-Black.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* Roboto */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("./Roboto/Roboto-Thin.ttf"); /* IE9 Compat Modes */
  src: local("Roboto"), url("./Roboto/Roboto-Thin.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("./Roboto/Roboto-Light.ttf"); /* IE9 Compat Modes */
  src: local("Roboto"), url("./Roboto/Roboto-Light.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./Roboto/Roboto-Regular.ttf"); /* IE9 Compat Modes */
  src: local("Roboto"), url("./Roboto/Roboto-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("./Roboto/Roboto-Medium.ttf"); /* IE9 Compat Modes */
  src: local("Roboto"), url("./Roboto/Roboto-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./Roboto/Roboto-Bold.ttf"); /* IE9 Compat Modes */
  src: local("Roboto"), url("./Roboto/Roboto-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("./Roboto/Roboto-Black.ttf"); /* IE9 Compat Modes */
  src: local("Roboto"), url("./Roboto/Roboto-Black.ttf") format("truetype"); /* Safari, Android, iOS */
}